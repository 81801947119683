@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.botones {
    border-radius: 0.7rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.dentro-botones{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center; 
}
.h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}
.h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: bold;
    }
.h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}
.tarjeta-blanca{
    padding: 1rem !important;
    background-color: white;
    border-radius: 1rem;
} 
li{
 color: black;
 margin-left: 0.4rem;

}
.back-to-top{
display: flex;
justify-content: flex-end;
border:none;
background-color: none;
}
.back-to-top-img{
width: 3rem;
margin: 1rem;
    }
.icon{
    width: 2rem;
}
.botn{
    display: flex;
    justify-content: flex-end ;
}
button{
border:none;
background-color: none;
}